import { Navbar, Nav, Form } from "react-bootstrap";
import MYZNavBar from "./myz-navbar";
import MorkBorgNavBar from "./mork-borg-navbar";
import KidsOnBikesNavBar from "./kids-on-bikes-navbar";
import ForbiddenLandsNavBar from "./forbidden-lands-navbar";
import BITDNavBar from "./bitd-navbar";
import BRNavBar from "./blade-runner-navbar";
import ExquisiteCorpsesNavBar from "./exquisite-corpses-navbar";
import ResourcesNavBar from "./resources-navbar";
import RavenHead from "../../../assets/smoke-raven-avatar.webp";
const NavBar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="md" sticky="top">
      <Navbar.Brand href="/">SmokeRaven</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <a href="/">
            <img src={RavenHead} alt="SmokeRaven" style={{ maxHeight: 40 }} />
          </a>

          <ForbiddenLandsNavBar />
          <BITDNavBar />
          <BRNavBar />
          <KidsOnBikesNavBar />
          <MorkBorgNavBar />
          <MYZNavBar />
          <ResourcesNavBar />
          <ExquisiteCorpsesNavBar />
          {/*TODO: use other navbar to get submenus: https://www.npmjs.com/package/react-bootstrap-submenu*/}
        </Nav>

        <Form inline="true">
          <div style={{ marginRight: 20 }}>
            <small>
              <span style={{ color: "white" }}>Discord Channel: </span>
              <a
                style={{
                  fontWeight: 600,
                  color: "white",
                  textDecoration: "underline",
                }}
                href="https://discord.gg/GMxHdxNP5Q"
                rel="noreferrer"
                target="_blank"
              >
                SmokeRaven News
              </a>
            </small>
          </div>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default NavBar;
