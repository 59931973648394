import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import NavBarBlades from "../../../assets/blades-in-the-dark/navbar-icon-blades-in-the-dark.webp";

const BITDNavBar = () => {
  return (
    <NavDropdown title="Blades in the Dark" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Brand href="#">
          <div style={{ textAlign: "center", padding: "0px 0px 0px 8px" }}>
            Blades in the Dark
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <img
              src={NavBarBlades}
              alt="SmokeRaven"
              style={{ maxHeight: 85 }}
            />
          </Nav>
          <NavDropdown title="Sessions 00-10" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/blades-in-the-dark-session-00">
              <b>00: The Bog Diggers</b> [11-30-2024]
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Tools & Info" id="basic-nav-dropdown">
            <NavDropdown.Item href="/blades-in-the-dark-overview">
              What Is Blades?
            </NavDropdown.Item>
            <NavDropdown.Item href="/blades-in-the-dark-classes-core">
              Classes
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </NavDropdown>
  );
};

export default BITDNavBar;
