import React from "react";
import { Route } from "react-router-dom";
import BITDSession00 from "../bitd/BITDSession00";
import BITDClassesCore from "../bitd/BITDClassesCore";
import BITDOverview from "../bitd/BITDOverview";

const BladesInTheDarkRoutes = () => {
  return [
    <Route path="/blades-in-the-dark-session-00" element={<BITDSession00 />} />,
    <Route
      path="/blades-in-the-dark-classes-core"
      element={<BITDClassesCore />}
    />,
    <Route path="/blades-in-the-dark-overview" element={<BITDOverview />} />,
  ];
};

export default BladesInTheDarkRoutes;
