import React from "react";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import kofi from "../../../src/assets/kofi-smokeraven.webp";
import flTitle from "../../../src/assets/tile-forbidden-lands-logo.webp";
import mbTitle from "../../../src/assets/tile-mork-borg-logo-horizontal-yellow.webp";
import brTitle from "../../../src/assets/tile-blade-runner.webp";
import kobTitle from "../../../src/assets/tile-logo-kids-on-bikes-white.webp";
import kobCover01 from "../../assets/kids-on-bikes/session01/session-01-cover.webp";
import kobCover02 from "../../assets/kids-on-bikes/session02/session-02-cover.webp";
import kobCover03 from "../../assets/kids-on-bikes/session03/session-03-cover.webp";
import kobCover04 from "../../assets/kids-on-bikes/session04/session-04-cover.webp";
import kobCover05 from "../../assets/kids-on-bikes/session05/session-05-cover.webp";
import fblCover01 from "../../assets/forbidden-lands/session01/fbl-session-01-cover.webp";
import fblCover02 from "../../assets/forbidden-lands/session02/fbl-session-02-cover.webp";
import fblCover03 from "../../assets/forbidden-lands/session03/fbl-session-03-cover.webp";
import fblCover04 from "../../assets/forbidden-lands/session04/fbl-session-04-cover.webp";
import fblCover05 from "../../assets/forbidden-lands/session05/fbl-session-05-cover.webp";
import fblCover06 from "../../assets/forbidden-lands/session06/fbl-session-06-cover.webp";
import fblCover07 from "../../assets/forbidden-lands/session07/fbl-session-07-cover.webp";
import fblCover08 from "../../assets/forbidden-lands/session08/fbl-session-08-cover.webp";
import fblCover09 from "../../assets/forbidden-lands/session09/fbl-session-09-cover.webp";
import fblCover10 from "../../assets/forbidden-lands/session10/fbl-session-10-cover.webp";
import fblCover11 from "../../assets/forbidden-lands/session11/fbl-session-11-cover.webp";
import fblCover12 from "../../assets/forbidden-lands/session12/fbl-session-12-cover.webp";
import fblCover13 from "../../assets/forbidden-lands/session13/fbl-session-13-cover.webp";
import bitdCover00 from "../../assets/blades-in-the-dark/session00/bitd-session-00-cover.webp";

import morkBorgSessionbanner from "../../../src/assets/mork-borg/session11/session-11-blood-relic.webp";

// import omnibus from "../../../src/assets/omnibus-cover-drive-thru-splash.webp";
import startPlayingAd from "../../../src/assets/blade-runner/ad-start-playing-blade-runner-1600x800.webp";

const HomePage = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../src/assets/background.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div style={{ fontFamily: "Orbitron" }}>
            <main
              role="main"
              className="inner cover text-center"
              style={{
                backgroundColor: "black",
                padding: "15px",
              }}
            >
              <Row>
                <Col lg={4} md={4} sm={4}>
                  <a
                    href="https://ko-fi.com/smokeraven667"
                    target="_blank"
                    rel="noreferrer"
                    className="tileHover"
                  >
                    <img
                      src={kofi}
                      alt="Kofi Tip Jar"
                      style={{
                        width: "100%",
                      }}
                    />
                  </a>
                </Col>
                <Col lg={8} md={8} sm={8}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ textAlign: "left", marginBottom: "10px" }}>
                      <div style={{ color: "white", paddingTop: "10px" }}>
                        <a
                          href="./blades-in-the-dark-session-00"
                          className="HomePageSessionLink"
                        >
                          Blades in the Dark 00: The Bog Diggers
                        </a>
                        <br />
                        In Limmerfield, on the shores of Crow's Foot near
                        Tangletown, Alastair "Paddy" and Griggs "The Fox" awaken
                        on their derelict fishing boat and decide it's time to
                        make something of their crew of bravos, The Bog Diggers.
                      </div>
                      <div style={{ color: "white", paddingTop: "10px" }}>
                        <a
                          href="./forbidden-lands-session-13"
                          className="HomePageSessionLink"
                        >
                          Forbidden Lands 13: The Five Seals
                        </a>
                        <br />
                        Ingmar the Plump is met and treasure sold. A deal is
                        struck regarding Ruenlocke. Wurda tells the legend of
                        The Five Seals.
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4} style={{ marginBottom: "25px" }}>
                  <a
                    href="./blades-in-the-dark-session-00"
                    className="tileHover"
                  >
                    <img
                      src={bitdCover00}
                      alt="Blades in the Dark Session 00"
                      style={{
                        width: "100%",
                      }}
                    />
                  </a>
                </Col>
                <Col md={4} style={{ marginBottom: "25px" }}>
                  <a href="./forbidden-lands-session-13" className="tileHover">
                    <img
                      src={fblCover13}
                      alt="Forbidden Lands Session 13"
                      style={{
                        width: "100%",
                      }}
                    />
                  </a>
                </Col>
                <Col md={4} style={{ marginBottom: "25px" }}>
                  <a href="./forbidden-lands-session-12" className="tileHover">
                    <img
                      src={fblCover12}
                      alt="Forbidden Lands Session 12"
                      style={{
                        width: "100%",
                      }}
                    />
                  </a>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div>
                    <a
                      href="./forbidden-lands-session-13"
                      className="tileHover"
                    >
                      <img
                        src={flTitle}
                        className="homeTile"
                        alt="Forbidden Lands"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="./kids-on-bikes-turnaround-rock-session-05"
                      className="tileHover"
                    >
                      <img
                        src={kobTitle}
                        className="homeTile"
                        alt="Kids on Bikes"
                      />
                    </a>
                  </div>
                  <div>
                    <a href="./blade-runner-overview" className="tileHover">
                      <img
                        src={brTitle}
                        className="homeTile"
                        alt="Blade Runner"
                      />
                    </a>
                  </div>
                  <div>
                    <a href="./mork-borg-classes-gallery" className="tileHover">
                      <img src={mbTitle} className="homeTile" alt="Mork Borg" />
                    </a>
                  </div>
                </Col>
              </Row>

              {/* GAME TILES ROW 1 */}
              <Row>
                <Col>
                  <div>
                    <a
                      href="./forbidden-lands-session-11"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover11}
                        alt="Forbidden Lands Session 11"
                        style={{
                          width: "100%",
                        }}
                      />
                    </a>

                    <a
                      href="./forbidden-lands-session-10"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover10}
                        alt="Forbidden Lands Session 10"
                        style={{
                          width: "100%",
                        }}
                      />
                    </a>

                    <a
                      href="./forbidden-lands-session-09"
                      className="tileHover homeGameTile"
                    >
                      <img src={fblCover09} alt="Forbidden Lands Session 09" />
                    </a>
                    <a
                      href="./forbidden-lands-session-08"
                      className="tileHover homeGameTile"
                    >
                      <img src={fblCover08} alt="Forbidden Lands Session 08" />
                    </a>
                    <a
                      href="./forbidden-lands-session-07"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover07}
                        alt="Forbidden Lands: Ruins of Ruenlocke"
                      />
                    </a>
                    <a
                      href="./kids-on-bikes-turnaround-rock-session-05"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={kobCover05}
                        alt="Kids on Bikes Session 05"
                        style={{
                          width: "100%",
                        }}
                      />
                    </a>
                    <a
                      href="./kids-on-bikes-turnaround-rock-session-04"
                      className="tileHover homeGameTile"
                    >
                      <img src={kobCover04} alt="Kids on Bikes Session 04" />
                    </a>

                    <a
                      href="./forbidden-lands-session-06"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover06}
                        alt="Forbidden Lands: Ruins of Ruenlocke"
                      />
                    </a>
                    <a
                      href="./kids-on-bikes-turnaround-rock-session-03"
                      className="tileHover homeGameTile"
                    >
                      <img src={kobCover03} alt="Kids on Bikes Session 03" />
                    </a>
                    <a
                      href="./forbidden-lands-session-05"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover05}
                        alt="Forbidden Lands: When Stars Fall"
                      />
                    </a>
                    <a
                      href="./kids-on-bikes-turnaround-rock-session-02"
                      className="tileHover homeGameTile"
                    >
                      <img src={kobCover02} alt="Kids on Bikes Session 02" />
                    </a>
                    <a
                      href="./forbidden-lands-session-04"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover04}
                        alt="Forbidden Lands: Murder in Northfall"
                      />
                    </a>
                    <a
                      href="./kids-on-bikes-turnaround-rock-session-01"
                      className="tileHover homeGameTile"
                    >
                      <img src={kobCover01} alt="Kids on Bikes Session 01" />
                    </a>
                    <a
                      href="./forbidden-lands-session-03"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover03}
                        alt="Forbidden Lands: Winter Spirits & Tundra Walk"
                      />
                    </a>
                    <a
                      href="./forbidden-lands-session-02"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover02}
                        alt="Forbidden Lands: The Elven Lighthouse"
                      />
                    </a>
                    <a
                      href="./forbidden-lands-session-02"
                      className="tileHover homeGameTile"
                    >
                      <img
                        src={fblCover01}
                        alt="Forbidden Lands: Freedom Day in Gomsten"
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              {/* GAME TILES ROW 2 */}
              {/* <Row>
                <Col>
                  <div>
                    <a href="./forbidden-lands-session-05">
                      <img
                        src={fblCover}
                        alt="Forbidden Lands: When Stars Fall"
                        style={{
                          width: "100%",
                          maxWidth: "600px",
                          float: "left",
                          height: "auto",
                          marginRight: 15,
                        }}
                      />
                    </a>
                    <a href="./kids-on-bikes-turnaround-rock-session-03">
                      <img
                        src={kobCover}
                        alt="Kids on Bikes Session 03"
                        style={{
                          marginTop: 0,
                          width: "100%",
                          maxWidth: "600px",
                          height: "auto",
                          float: "left",
                          marginRight: 15,
                        }}
                      />
                    </a>
                  </div>
                </Col>
              </Row> */}
              {/* GAME TILES ROW 3 */}
              {/* <Row>
                <Col>
                  <div>
                    <a href="./mork-borg-session-11-malum-mortis">
                      <img
                        src={morkBorgSessionbanner}
                        alt="mork borg: finding the blood relic at the death ziggurat"
                        style={{
                          width: "100%",
                          maxWidth: "600px",
                          float: "left",
                          height: "auto",
                          marginRight: 15,
                        }}
                      />
                    </a>
                  </div>
                </Col>
              </Row> */}
            </main>
          </div>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default HomePage;
