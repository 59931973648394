import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/blades-in-the-dark/logo-blades-in-the-dark.png";
import sectionDivider from "../../../../src/assets/blades-in-the-dark/session00/blades-in-the-dark-page-banner-session-00.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./blades-in-the-dark-styles.scss";
import paddy from "../../../../src/assets/blades-in-the-dark/session00/alistair-paddy.webp";
import griggs from "../../../../src/assets/blades-in-the-dark/session00/griggs-the-fox.webp";
import bannat from "../../../../src/assets/blades-in-the-dark/session00/bannat.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const BITDSession00 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Limmerfield Scoundrels Storm Lucky Star Gamblng Den",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleBITD",
      text: (
        <div className="bitdBodyText">
          <ImageComponent
            image={paddy}
            caption="Alistair 'Paddy'"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="There is Alistair, better known as 'Paddy' to those who are not
            family or desiring a punch in the face. Paddy is a CUTTER from
            Skovland addicted to bare-knuckle fighting. He worked as a laborer
            in Skovland and figured Doskvol would have better opportunites for a
            strong son of a bitch. What he lacks in finesse he makes up for with
            knocking folks on their ass."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={griggs}
            caption="The Fox"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Then there is Griggs. Some call him 'The Fox' because his skills as
            a LURK are not half-bad. He's a Severosi Thorn Devil who had to flee
            his homeland when Imperial Navy police finally raided the Thorn
            Devil camp to put an end to their anti-Imperial sabotage. He's
            dedicated to poking holes in the Imperial dominance of The Shattered
            Isles, but not as dedicated as he is to gambling at the Silver Stag
            casino and making some coin to keep the cards dealing and the dice
            rolling."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <p>
            Once fine day (and by fine day we mean a foggy, drizzly bastard of a
            day), there are two scoundrels in Crow's Foot.
          </p>
          <p>
            There is Alistair, better known as 'Paddy' to those who are not
            family or desiring a punch in the face. Paddy is a CUTTER from
            Skovland addicted to bare-knuckle fighting. He worked as a laborer
            in Skovland and figured Doskvol would have better opportunites for a
            strong son of a bitch. What he lacks in finesse he makes up for with
            knocking folks on their ass.
          </p>
          <p>
            Then there is Griggs. Some call him "The Fox" because his skills as
            a LURK are not half-bad. He's a Severosi Thorn Devil who had to flee
            his homeland when Imperial Navy police finally raided the Thorn
            Devil camp to put an end to their anti-Imperial sabotage. He's
            dedicated to poking holes in the Imperial dominance of The Shattered
            Isles, but not as dedicated as he is to gambling at the Silver Stag
            casino and making some coin to keep the cards dealing and the dice
            rolling.
          </p>
          <p>
            Paddy wakes up, scratches his wild patch of hair, runs his gnarled
            boxer hands over his rough face, and announces,{" "}
            <span style={{ color: "yellow" }}>
              "You know Griggs . . . I'm tired of waking up on this derelict
              fishing boat and sittin' with my thumb up me ass all day. I say we
              start a crew."
            </span>
          </p>
          <p>
            Griggs is making a cup of rancid mushroom tea at the tiny galley
            stove and nods.{" "}
            <span style={{ color: "yellow" }}>
              "A gang eh? What are we gonna call ourselves?"
            </span>
          </p>
          <p>
            <span style={{ color: "yellow" }}>
              "I figure . . . The Bog Diggers."
            </span>
          </p>
          <p>
            Griggs nods.{" "}
            <span style={{ color: "yellow" }}>"Ok. What do we do today?"</span>
          </p>
          <p>
            <span style={{ color: "yellow" }}>
              "Well, I may be fresh off the boat"
            </span>
            , Paddy muses,{" "}
            <span style={{ color: "yellow" }}>
              "but I know enough that The Crows mostly run Crow's Foot. They
              don't seem to be bothering us down here in Limmerfield, but I
              figure we should learn more about these bastards you know?"
            </span>
          </p>
          <p>
            Griggs sips his tea and hands a cup to Paddy. Paddy tastes the brew
            and winces as Griggs intones,{" "}
            <span style={{ color: "yellow" }}>
              "They say The Crows run gambling dens; let's go to a bar and see
              if we can get the scuttlebutt on a gambling den."
            </span>
          </p>
          <p>
            <span style={{ color: "yellow" }}>
              "Gods this tea tastes like it was strained through a sock"
            </span>
            , Paddy complains.
          </p>
          <p>
            <span style={{ color: "yellow" }}> "It was"</span> says Griggs with
            a straight face.
          </p>
          <p>
            <span style={{ color: "yellow" }}>
              "And that's why we hafta get our asses in gear takin' over
              Doskvol!"
            </span>
          </p>
          <p>
            And with that, Paddy and Griggs wander to the local watering hole to
            see what's what.
          </p>
          <hr className="hrBITD" />
          <ImageComponent
            image={bannat}
            caption="Bannat"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Bannat is a female akorosi thief ('prosthetic, crippled') from Coalridge: The Crucible.<br/>They are a destructive person whose goal is Chaos, Destruction by means of Manipulation.<br/>She has a prosthetic left leg which is varnished wood with metal rings to strengthen it."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            At the watering hole, Paddy recognizes some rough fellas from the
            pit fights over at The Docks. He has heard them talk about gambling
            their fight winnings. And so our score begins with an ENGAGEMENT
            ROLL for following the fighters to an illegal gambling den. It's a
            Controlled position since the fighters are drunk and have been
            watched by our scoundrels for a bit. The effect will be Great, good
            or bad, since the inebriation of the fighters is sure to make
            whatever happens wild. Paddy rolls HUNT with SUCCESS.
          </p>
          <p>
            The Bog Diggers follow the fighters to a broken old building that
            used to be a meat shoppe. They knock at the back door with a
            rhythmic pattern. A slit in the door opens, a set of eyes and bridge
            of nose appear, then the door opens to let the fighters in.
            Confident in the knock pattern, Paddy and Griggs approach and knock
            with the same rhythm. The same eyes and nose appear. There is a
            whispered question:{" "}
            <span style={{ color: "yellow" }}>
              "What is the secret word for the day?"
            </span>
          </p>
          <p>
            Paddy frowns as he was too far away to hear the earlier entrants
            responding to a question. Falling back on habit, Paddy tries to
            COMMAND the doorman.
            <span style={{ color: "yellow" }}>"You see this?"</span>, Paddy asks
            leaning in close to the door slot and running his hands over he
            scarred face and showing off his gnarled hands.
            <span style={{ color: "yellow" }}>
              "I just want to ask if you can let us in eh?"
            </span>{" "}
            Paddy rolls a partial success: the doorman lets them in, but frowns
            in shame at caving to intimidation. He seems to be harboring
            resentment that could come into play later.
          </p>
          <p>
            Paddy and Griggs descend some steep stairs through a cloud of cheap
            tobacco smoke. The bottom of the old meat shoppe has a few wooden
            pillars puncturing a room with a half dozen cheap tables and a
            makeshift bar at the back. Griggs sits down to gamble at the one
            active table while Paddy leans on the canted bar and orders a drink.
          </p>
          <p>
            At the table, Griggs tries to CONSORT with the other gamblers to get
            information about the gambling den. His friendliness is only
            partially successful and one of the other players grumbles,
            <span style={{ color: "yellow" }}>
              "Quiet Severosi, play cards."
            </span>{" "}
            Griggs falls back to a desperate position.
          </p>
          <p>
            Seeing that being nice isn't working, Griggs taps out of the game
            and approaches Paddy at the bar.{" "}
            <span style={{ color: "yellow" }}>
              "There're not too friendly."
            </span>{" "}
            And so, Paddy takes Griggs' spot at the table.
          </p>
          <p>
            Griggs had scouted the room and learned there are two male gamblers
            and a female gambler. Also, there is a back staircase leading
            upstairs guarded by a huge rough fellow with a metal knuckle duster
            on each hand. The tables in the room show telltale signs of cuts and
            gouges as well as darkened stains that tell the tale of the Lucky
            Star being rough at times.
          </p>
          <p>
            After a couple of hands, Paddy focuses on the female player. She is
            an Akorosi woman. Thin, narrow face, and arms and legs that fidget
            constantly.
            <span style={{ color: "yellow" }}>"Who owns this place?"</span>,
            Paddy asks her.{" "}
            <span style={{ color: "yellow" }}>"I, uh, I don't know."</span> she
            spits back, her hands tapping her cards on the table in a scuttling
            stacco pattern.{" "}
            <span style={{ color: "yellow" }}>"What's your name?"</span> she
            asks.
          </p>
          <p>
            Paddy learns her name in Bannat. She occasionally looks at him, then
            at her cards, then her hands, then back at him. Her fingers dance a
            a drumming pattern that seems intentional, but Paddy cannot make any
            sense if there is a pattern or message there.{" "}
            <span style={{ color: "yellow" }}>"What about him?"</span> Paddy
            asks, indicating the guard.{" "}
            <span style={{ color: "yellow" }}>
              "Oh, that's Rollo. He's dangerous, don't mess with him."
            </span>
            The CONSORT roll to get this banter going with Bannat is a partial
            success, so the consequence is the other two male players tell Paddy
            to shut the fuck up and play. Paddy responds with a COMMAND to try
            and start a fight with the men.
            <span style={{ color: "yellow" }}>
              "You don't tell me what to do."
            </span>{" "}
            Paddy says as Griggs sets a drink next to him and nods indicating he
            is ready to back Paddy's play (which also counts a helping actionso
            Paddys COMMAND roll had a bonus die at the cost of Grigg's taking
            one streess)
          </p>
          <p>
            The COMMAND roll to try and start a fight is partial success. This
            results in the men holding up their hands and claiming they don't
            want any trouble. However, as they backpedal their previously shitty
            attitudes, Paddy sees the doorman from above at the foot of the
            stairs. He is smirking like the cat that caught the mouse. The other
            players are looking over Paddy's shoulder. Paddy sees that Bannat,
            who sits directly across from him, is also looking over Paddy's
            shoulder. Paddy turns his head to see the mighty Rollo standing
            inches behind his chair.
          </p>
          <p>
            <span style={{ color: "yellow" }}>
              "You gotta go. I ain't seen you in here before and fights are bad
              for business."
            </span>{" "}
            says Rollo.
            <br />
            Paddy stands up and Bannat stands up as well, seemingly backing
            Paddy's play. Paddy notices Bannat has a prosthetic wooden leg
            ringed with metal reinforcement. He also sees Griggs go low to the
            ground and stick a leg out behind Rollo. Paddy grins and says{" "}
            <span style={{ color: "yellow" }}>
              "We're not going anywhere fella"
            </span>{" "}
            as he launches his fist at Rollo. Paddy's SKIRMISH roll is FAILURE
            which means his fist hits Rollos broad chest and it feels like
            punching a bag of sand. In answer, Rollo's knuckle duster crashes
            into Paddy's face and gives him a BROKEN JAW (level 2 injury).
          </p>
          <p>
            As Griggs and Paddy stand shocked and Rollo lets out a low chuckle,
            there is a thunderous explosion. After a cloud of black gunsmoke
            clears, Rollo is staring at hole in his chest in disbelief. Rollo
            crashes backward as Bannat laughs. She is standing, cards still in
            one hand and a smoking fat shotgun pistol in the other hand. The
            other gamblers and the barkeep back away hands in the air and run
            for the stairs leading to the door. Paddy grabs the money off the
            table and Bannat giggles,{" "}
            <span style={{ color: "yellow" }}>
              "Oh, you two do this for money? I do this for FUN!!"
            </span>
          </p>
          <p>
            Paddy, Griggs and Bannat manhandle the barkeep and drag him up the
            back stairs Rollo was guarding into an office. Aside from a desk
            covered with accounting paperwork and an old typewriter, there is a
            safe. Paddy tries to force the barkeep to open the safe but the
            rolls fail. The trio of scoundrels hear the doorman outside the
            building shouting and drawing some of The Crow gang from their
            headquarters across the street from the Lucky Star. Griggs is
            desperate and tries to WRECK the safe by smashing it with the heavy
            typewriter. It's a failure and he instead suffers a level 1 harm
            SPRAINED WRIST. Realizing it would be foolhardy to fuck with the
            safe as voices are raising and crows enter the club, Paddy says,{" "}
            <span style={{ color: "yellow" }}>
              "Well, I guess this is for the fun today"
            </span>{" "}
            and our scoundrels flee at top speed.
          </p>
          <p>
            The rolls to get back to their lair are marginal. There is a close
            moment when Paddy WRECKS hes way through a small home and the noise
            draws the crows down on them. However, a couple more decent rolls
            and our scoundrels make it home. Bannat veers away as Paddy and
            Griggs scramble into their boat lair. She disappears with a shout,
            &nbsp;
            <span style={{ color: "yellow" }}>
              "See you later boys; that was fun."
            </span>
          </p>
          <p>
            We go through downtime activities. Griggs spends both of his
            activities trying to heal and gets about halfway there. Paddy gets a
            healing slot filled on his broken jaw, and rather than do it a
            second time he opts to remove some heat from the crew by talking to
            Griggs' bluecoat friend. Unfortunately, the bluecoats showed up on
            the entanglement roll so they had to be paid of with three of the
            four coin owned by the crew to walk away. The Bog Diggers have a
            fairly full heat track since their operation was so loud and a death
            was involved. That said, they also got four reputation for going
            against a faction of TIER 2 whilst the Bog Diggers are a mere TIER
            0. Griggs pays the last of the crew's coin for an extra downtime
            activity so he can relax at the Silver Stag, gambling to remove some
            of the stress he accumulated with all the helping dice he give Paddy
            throughout the session.
          </p>
          <p>
            NEXT TIME: we need to update faction relationship statuses and
            discuss Bannat returning to the lair to chat about The Crows. She
            does know about them, and couldn't talk in front of strangers at the
            Lucky Star. But now that she knows the Bog Diggers are a fun bunch
            of boys she's willing to tell them about The Crows.
            <br />
            <br />
            <em>
              What do we learn about The Crows? Do The Crows know who killed
              Rollo? Is Bannat with a faction? If so, which faction? Did her
              drumming finger patterns mean anything?
            </em>
          </p>

          <p>
            <a href="#pageTop">Back to Top</a>
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/blades-in-the-dark/background-doskvol_street-scene-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-blades-in-the-dark  ">
                In Limmerfield, on the shores of Crow's Foot near Tangletown,
                Alastair "Paddy" and Griggs "The Fox" awaken on their derelict
                fishing boat and decide it's time to make something of their
                crew of bravos, The Bog Diggers.
                {/* <br />
                <small>
                  <br />☠ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ⇒
                </small> */}
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BITDSession00;
